// 58164
var videourlCarousel = '';
var videourlCarouselAuto = '';
var modalTitle = '';
var modalDescription = '';

$(document).on('click', '.video-carousel-thumbnail .btn-video-popup', function () {
    videourlCarousel = $(this).attr('data-src');
    videourlCarouselAuto = videourlCarousel + "?autoplay=1";
    modalTitle = $(this).attr('data-title');
    modalDescription = $(this).attr('data-description');
});

$("#videoCarouselModal").on('shown.bs.modal', function () {
    $("#videoCarouselFrame").each(function () {
        (this.contentWindow || this.documentWindow).location.replace(videourlCarouselAuto);
    });
    $(".video-carousel-title__modal").html(modalTitle);
    $(".video-carousel-description__modal").html(modalDescription);
});

$("#videoCarouselModal").on('hide.bs.modal', function () {
    $("#videoCarouselFrame").each(function () {
        (this.contentWindow || this.documentWindow).location.replace('');
    });
    $(".video-carousel-title__modal").html('');
    $(".video-carousel-description__modal").html('');
});
